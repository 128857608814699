<template>
  <div class="page" id="create-call-survey">
    <div class="p-3 card">
      <h1 class="m-0">
        {{ $t('call-survey.create.title') }}
      </h1>

      <p class="m-0">
        {{ $t('call-survey.create.description') }}
      </p>

      <div class="survey">
        <div class="p-3 card">
          <div class="mb-4 basic">
            <h4 class="mt-0 mb-2">
              {{ $t('call-survey.create.form.titles.basic') }}
            </h4>
            <div class="row">
              <div class="col-12 col-lg-4 col-md-6">
                <Field
                  :label="$t('call-survey.create.form.name.label')"
                  :placeholder="$t('call-survey.create.form.name.placeholder')"
                  :info="$t('call-survey.create.form.name.info')"
                  v-model="model.name"
                  :error="errors[`name`]"
                >
                </Field>
              </div>

              <div class="col-12 col-lg-4 col-md-6">
                <Field
                  :label="$t('call-survey.create.form.dtmf-timeout.label')"
                  :error="errors[`dtmfTimeout`]"
                  :info="$t('call-survey.create.form.dtmf-timeout.info')"
                >
                  <b-form-input
                    class="border"
                    v-model="model.dtmfTimeout"
                    type="number"
                    min="5"
                    @keydown="onlyAcceptNumbers"
                  />
                </Field>
              </div>
            </div>
          </div>

          <div class="mb-4 sounds">
            <h4 class="mt-0 mb-2">
              {{ $t('call-survey.create.form.titles.sounds') }}
            </h4>
            <div class="row">
              <div class="col-12 col-lg-6">
                <Field
                  :label="$t('call-survey.create.form.startSound.label')"
                  :info="$t('call-survey.create.form.startSound.info')"
                  :error="errors[`startSound`]"
                >
                  <Dropzone
                    :options="{
                      paramName: 'file',
                      acceptedFiles: '.mp3',
                      maxFiles: 1
                    }"
                    v-model="model.startSound"
                  >
                    <div class="dropzone-custom-content">
                      <h3 class="dropzone-custom-title">
                        {{ $t('call-survey.create.form.startSound.title') }}
                      </h3>
                      <div class="subtitle">
                        {{ $t('call-survey.create.form.startSound.subtitle') }}
                      </div>
                      <div class="description">
                        {{
                          $t('call-survey.create.form.startSound.description', {
                            exts: '.mp3'
                          })
                        }}
                      </div>
                    </div>
                  </Dropzone>
                </Field>
              </div>
              <div class="col-12 col-lg-6">
                <Field
                  :label="$t('call-survey.create.form.wrongEntrySound.label')"
                  :info="$t('call-survey.create.form.wrongEntrySound.info')"
                  :error="errors[`wrongEntrySound`]"
                >
                  <Dropzone
                    :options="{
                      paramName: 'file',
                      acceptedFiles: '.mp3',
                      maxFiles: 1
                    }"
                    v-model="model.wrongEntrySound"
                  >
                    <div class="dropzone-custom-content">
                      <h3 class="dropzone-custom-title">
                        {{
                          $t('call-survey.create.form.wrongEntrySound.title')
                        }}
                      </h3>
                      <div class="subtitle">
                        {{
                          $t('call-survey.create.form.wrongEntrySound.subtitle')
                        }}
                      </div>
                      <div class="description">
                        {{
                          $t(
                            'call-survey.create.form.wrongEntrySound.description',
                            {
                              exts: '.mp3'
                            }
                          )
                        }}
                      </div>
                    </div>
                  </Dropzone>
                </Field>
              </div>
              <div class="col-12 col-lg-6">
                <Field
                  :label="$t('call-survey.create.form.endSound.label')"
                  :info="$t('call-survey.create.form.endSound.info')"
                  :error="errors[`endSound`]"
                >
                  <Dropzone
                    :options="{
                      paramName: 'file',
                      acceptedFiles: '.mp3',
                      maxFiles: 1
                    }"
                    v-model="model.endSound"
                  >
                    <div class="dropzone-custom-content">
                      <h3 class="dropzone-custom-title">
                        {{ $t('call-survey.create.form.endSound.title') }}
                      </h3>
                      <div class="subtitle">
                        {{ $t('call-survey.create.form.endSound.subtitle') }}
                      </div>
                      <div class="description">
                        {{
                          $t('call-survey.create.form.endSound.description', {
                            exts: '.mp3'
                          })
                        }}
                      </div>
                    </div>
                  </Dropzone>
                </Field>
              </div>
            </div>
          </div>

          <div class="mb-4 questions">
            <div
              class="d-flex justify-content-between align-items-center my-2 head"
            >
              <h4 class="mb-2 m t-0">
                {{ $t('call-survey.create.form.titles.questions') }}
              </h4>
              <button class="px-3 py-2 btn btn-primary" @click="onAddQuestion">
                {{ $t('call-survey.create.form.questions.add') }}
              </button>
            </div>

            <div class="row mb-2">
              <div class="col-12 col-lg-4 col-md-6">
                <Field
                  :label="
                    $t('call-survey.create.form.max-question-attempts.label')
                  "
                  :error="errors[`maxQuestionAttempts`]"
                  :info="
                    $t('call-survey.create.form.max-question-attempts.info')
                  "
                >
                  <b-form-input
                    class="border"
                    v-model="model.maxQuestionAttempts"
                    type="number"
                    min="1"
                    @keydown="onlyAcceptNumbers"
                  />
                </Field>
              </div>
            </div>

            <div class="row">
              <div
                class="col-12 col-lg-4"
                v-for="(question, idx) in model.questions"
                :key="question.id"
              >
                <div class="question">
                  <div class="head">
                    <Field
                      className="flex-1"
                      :label="
                        $t('call-survey.create.form.questions.answerType.label')
                      "
                      :info="
                        $t('call-survey.create.form.questions.answerType.info')
                      "
                      :error="errors[`questions[${idx}].answerType`]"
                    >
                      <b-form-select
                        class="border"
                        v-model="question.answerType"
                        :options="answerTypes"
                      ></b-form-select>
                    </Field>
                    <button
                      class="btn btn-danger"
                      @click="onRemoveQuestion(idx)"
                      v-if="model.questions.length > 1"
                    >
                      <i class="fa fa-times"></i>
                    </button>
                  </div>
                  <Field
                    :label="$t('call-survey.create.form.questions.sound.label')"
                    :info="$t('call-survey.create.form.questions.sound.info')"
                    :error="errors[`questions[${idx}].sound`]"
                  >
                    <Dropzone
                      :options="{
                        paramName: 'file',
                        acceptedFiles: '.mp3',
                        maxFiles: 1
                      }"
                      v-model="question.sound"
                    >
                      <div class="dropzone-custom-content">
                        <h3 class="dropzone-custom-title">
                          {{
                            $t('call-survey.create.form.questions.sound.title')
                          }}
                        </h3>
                        <div class="subtitle">
                          {{
                            $t(
                              'call-survey.create.form.questions.sound.subtitle'
                            )
                          }}
                        </div>
                        <div class="description">
                          {{
                            $t(
                              'call-survey.create.form.questions.sound.description',
                              {
                                exts: '.mp3'
                              }
                            )
                          }}
                        </div>
                      </div>
                    </Dropzone>
                  </Field>
                </div>
              </div>
            </div>
          </div>

          <b-button
            class="mt-4 px-3 py-2"
            variant="primary"
            @click="onSubmit"
            :disabled="loading"
          >
            {{ $t('call-survey.create.form.create') }}
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Field from '../../../elements/Field.vue'
import Dropzone from '../../../elements/Dropzone.vue'
import { onlyAcceptNumbers, removeNullEntries } from '../../../utils/utils'
import { array, mixed, number, object, string } from 'yup'
import vocabService from '../../../services/vocab.service.js'
import { yupToKV } from '../../../utils/yup.js'
import CallSurveyService from '../../../services/call-survey.service.js'
import momentTimezone from 'moment-timezone'
import { timezones } from '../../../constants/timezones.js'
import PostCallSurveyService from '../../../services/post-call-survey.service.js'

const defaultQuestion = {
  id: new Date().getTime(),
  sound: null,
  answerType: ''
}
const defaultTimeSlot = {
  id: new Date().getTime(),
  fromTime: '',
  toTime: ''
}
const defaultCaller = {
  id: new Date().getTime(),
  destination: '',
  callerNumber: ''
}

export default {
  name: 'CreateCallSurvey',
  components: {
    Field,
    Dropzone
  },
  data() {
    return {
      questions: [],
      timePickerConfig: {
        wrap: true,
        altFormat: 'H:i',
        dateFormat: 'H:i',
        enableSeconds: false,
        enableTime: true,
        noCalendar: true
      },
      answerTypes: [
        { text: '1 - 5', value: 'one_five' },
        { text: '1 - 10', value: 'one_ten' },
        { text: 'yes (1) or no (2)', value: 'yes_no' }
      ],
      model: {
        name: '',
        trialsCount: 1,
        concurrencyCalls: 1,
        delayMinutesBetweenTrials: 5,
        dtmfTimeout: 5,
        startSound: null,
        endSound: null,
        wrongEntrySound: null,
        timezone: '',
        allowDTMFInputDuringPlayback: false,
        maxQuestionAttempts: 1,
        callers: [{ ...defaultCaller }],
        questions: [{ ...defaultQuestion }],
        timeSlots: [{ ...defaultTimeSlot }]
      },
      errors: {},
      countries: [],
      selectedCountries: [],
      callerNumbers: [],
      loading: false
    }
  },
  computed: {
    timezones() {
      return timezones
    },
    schema() {
      return object().shape({
        name: string()
          .trim()
          .required(this.$t('call-survey.create.form.name.required')),
        dtmfTimeout: number()
          .min(
            5,

            this.$t('call-survey.create.form.dtmf-timeout.min', { min: 5 })
          )
          .required(this.$t('call-survey.create.form.dtmf-timeout.required')),
        startSound: mixed().required(
          this.$t('call-survey.create.form.startSound.required')
        ),
        endSound: mixed().required(
          this.$t('call-survey.create.form.endSound.required')
        ),
        wrongEntrySound: mixed().required(
          this.$t('call-survey.create.form.wrongEntrySound.required')
        ),
        maxQuestionAttempts: number()
          .min(
            1,
            this.$t('call-survey.create.form.max-question-attempts.min', {
              min: 1
            })
          )
          .required(
            this.$t('call-survey.create.form.max-question-attempts.required')
          ),
        questions: array()
          .of(
            object().shape({
              sound: mixed().required(
                this.$t('call-survey.create.form.questions.sound.required')
              ),
              answerType: string()
                .trim()
                .required(
                  this.$t(
                    'call-survey.create.form.questions.answerType.required'
                  )
                )
            })
          )
          .min(1, this.$t('call-survey.create.form.questions.min'))
      })
    },
    timezone() {
      let timezone = momentTimezone.tz.guess()
      const regex = new RegExp('^[a-zA-Z_]+\\/[a-zA-Z_]+$')
      if (!regex.test(timezone)) {
        timezone = 'Asia/Riyadh'
      }
      return timezones.find(tz => tz.id === timezone)
    }
  },
  methods: {
    onlyAcceptNumbers,
    onAddQuestion() {
      this.model.questions.push({
        ...defaultQuestion,
        id: new Date().getTime()
      })
    },
    onRemoveQuestion(idx) {
      this.model.questions.splice(idx, 1)
      this.errors[`questions[${idx}].sound`] = null
      this.errors[`questions[${idx}].answerType`] = null
    },
    // handlers
    handleAddCaller() {
      this.model.callers.push({
        ...defaultCaller,
        id: new Date().getTime()
      })
    },
    handleRemoveCaller(index) {
      const caller = this.model.callers[index]
      this.selectedCountries = this.selectedCountries.filter(
        country => country !== caller.destination
      )

      this.model.callers.splice(index, 1)
      this.errors[`callers[${index}].destination`] = null
      this.errors[`callers[${index}].callerNumber`] = null
    },
    onCountryChange(value, idx) {
      const temp = [...this.selectedCountries]
      temp[idx] = value
      this.selectedCountries = temp
    },
    handleAddTimeSlot() {
      this.model.timeSlots.push({
        ...defaultTimeSlot,
        id: new Date().getTime()
      })
    },
    handleRemoveTimeSlot(index) {
      this.model.timeSlots.splice(index, 1)

      this.errors[`timeSlots[${index}].fromTime`] = null
      this.errors[`timeSlots[${index}].toTime`] = null
    },
    // api calls
    async getCountries() {
      const countries = await vocabService.getCountries()
      this.countries = countries
    },
    async getCallerNumbers() {
      const callerNumbers = await vocabService.getCallerIds()
      this.callerNumbers = callerNumbers.map(number => ({
        text: number,
        value: number
      }))
    },
    async onSubmit() {
      try {
        this.loading = true
        this.errors = {}
        // get the data and remove all keys that's null
        const data = removeNullEntries({
          ...this.model,
          startSound: this.model.startSound ? this.model.startSound[0] : null,
          endSound: this.model.endSound ? this.model.endSound[0] : null,
          wrongEntrySound: this.model.wrongEntrySound
            ? this.model.wrongEntrySound[0]
            : null,
          questions: this.model.questions.map(question => ({
            ...question,
            sound: question.sound ? question.sound[0] : null
          })),
          dtmfTimeout: parseInt(this.model.dtmfTimeout),
          maxQuestionAttempts: parseInt(this.model.maxQuestionAttempts)
        })

        await this.schema.validate(data, { abortEarly: false }).catch(err => {
          this.errors = yupToKV(err)
        })

        if (!this.schema.isValidSync(data)) {
          return
        }

        // upload files
        const startSoundPromise = CallSurveyService.uploadSurveySound(
          data.startSound
        )
        const wrongEntrySoundPromise = CallSurveyService.uploadSurveySound(
          data.wrongEntrySound
        )
        const endSoundPromise = CallSurveyService.uploadSurveySound(
          data.endSound
        )

        const questionsSoundPromises = data.questions.map(question =>
          CallSurveyService.uploadSurveySound(question.sound)
        )

        const [
          startSoundRes,
          wrongEntrySoundRes,
          endSoundRes,
          ...questionsSoundRes
        ] = await Promise.all([
          startSoundPromise,
          wrongEntrySoundPromise,
          endSoundPromise,
          ...questionsSoundPromises
        ])

        const modelData = {
          ...data,
          startSoundFileName: startSoundRes.data.originalName,
          startSoundFilePath: startSoundRes.data.path,
          endSoundFileName: endSoundRes.data.originalName,
          endSoundFilePath: endSoundRes.data.path,
          wrongAnswerSoundFileName: wrongEntrySoundRes.data.originalName,
          wrongAnswerSoundFilePath: wrongEntrySoundRes.data.path,
          questions: data.questions.map((question, idx) => ({
            soundFileName: questionsSoundRes[idx].data.originalName,
            soundFilePath: questionsSoundRes[idx].data.path,
            type: question.answerType
          }))
        }

        await PostCallSurveyService.createSurvey(modelData)
        this.toast(this.$t('call-survey.create.toasts.survey-created'))
        this.$router.push({ name: 'post-call-surveys-list' })
      } catch (err) {
        const errorMessage = err.response
          ? err.response.data.message
          : err.message
        console.error(errorMessage)
        this.toast(errorMessage, {
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    }
  },
  mounted() {
    this.getCountries()
    this.getCallerNumbers()
    this.model.timezone = this.timezone
  }
}
</script>

<style scoped lang="scss">
.questions {
  .question {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;

    .head {
      display: flex;
      align-items: center;
      .field {
        flex: 1;
      }
      gap: 0.5rem;
      button {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0.5rem 1rem;
        margin-top: 1.5rem;
      }
    }
  }
}
</style>
